import React, { FC } from "react";
import Slider from "react-slick";
import CarouselCard from "../carouselCard/carouselCard";
import { css } from "@emotion/react";
import media from "../breakpoints";
import next from "../../images/next.svg";
import nextHover from "../../images/next_hover.svg";
import nextFocus from "../../images/next_focus.svg";
import prev from "../../images/prev.svg";
import prevHover from "../../images/prev_hover.svg";
import prevFocus from "../../images/prev_focus.svg";
interface Props {
  data: {
    feedback: string;
    hint: string;
    name: string;
    title: string;
    photo: string;
    logo: string;
    image: string;
    mobileImage?: boolean;
    company?: string;
  }[];
}
const Carousel: FC<Props> = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  const sliderWrapper = css`
    margin-bottom: 80px;

    .slick-dots li button::before {
      font-size: 11px;
      color: #d9dbe1 !important;
      opacity: 1 !important;
    }
    ul.slick-dots li button:hover::before {
      color: #273372 !important;
    }
    ul.slick-dots li button:focus::before {
      color: #141a39 !important;
    }
    .slick-dots li.slick-active button::before {
      opacity: 1 !important;
      color: #3147ff !important;
    }
    .slick-list {
      padding-bottom: 72px;
      margin: 0 auto;
      max-width: 1007px;
    }
    .slick-prev {
      margin-left: 24px;
      width: 48px;
      height: 48px;
      z-index: 99;
      ${media("md")} {
        display: none !important;
      }
    }
    .slick-prev:before {
      content: "";
      background: url(${prev}) center/cover no-repeat;
      width: 48px;
      height: 48px;
      display: block;
    }
    .slick-prev:hover::before {
      background: url(${prevHover}) center/cover no-repeat;
    }
    .slick-prev:focus::before {
      background: url(${prevFocus}) center/cover no-repeat;
    }
    .slick-next {
      margin-right: 25px;
      width: 48px;
      height: 48px;
      z-index: 99;
      ${media("md")} {
        display: none !important;
      }
    }
    .slick-next:before {
      content: "";
      background: url(${next}) center/cover no-repeat;
      width: 48px;
      height: 48px;
      display: block;
    }
    .slick-next:hover::before {
      background: url(${nextHover}) center/cover no-repeat;
    }
    .slick-next:focus::before {
      background: url(${nextFocus}) center/cover no-repeat;
    }
  `;
  return (
    <div css={sliderWrapper}>
      <Slider {...settings}>
        {data.map((reviewer, index) => (
          <CarouselCard
            key={index}
            feedback={reviewer.feedback}
            hint={reviewer.hint}
            name={reviewer.name}
            title={reviewer.title}
            photo={reviewer.photo}
            logo={reviewer.logo}
            image={reviewer.image}
            mobileImage={reviewer.mobileImage}
            company={reviewer.company}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
