import React, { FC } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import media from "../breakpoints";

interface Props {
  feedback: string;
  hint: string;
  name: string;
  title: string;
  photo: string;
  logo: string;
  image: string;
  mobileImage?: boolean;
  company?: string;
}
const CarouselCard: FC<Props> = ({
  feedback,
  hint,
  name,
  title,
  photo,
  logo,
  image,
  mobileImage,
  company,
}) => {
  const shadow = css`
    box-shadow: 0px 23.1429px 25px -15.4286px rgba(13, 40, 59, 0.2);
    overflow: hidden;
  `;
  const CardSize = styled.div`
    max-width: 1007px;
    min-height: 424px;
    margin: 0 auto;
    @media screen and (min-width: 992px) and (max-width: 1279.98px) {
      max-width: 800px;
    }
    .banner {
      background: url(${image}) center/cover no-repeat;
      width: 100%;
      max-width: 315px;
      min-height: 220px;
      ${media("md")} {
        max-width: 100%;
      }
    }
    .carousel-content {
      max-width: 520px;
      margin: 0 auto;
    }
  `;
  return (
    <CardSize className="card-size w-full h-full">
      <div
        className="flex flex-row justify-start w-full rounded-2xl bg-white border border-solid border-blue-border mt-12 md:flex-col"
        css={shadow}
      >
        <div
          className={`banner flex-1 ${mobileImage ? "" : "md:hidden"}`}
        ></div>
        <div className="carousel-content relative w-full h-full flex flex-col justify-start px-5 pt-16 pb-9">
          <p
            className="font-Inter mb-9 font-semibold text-blue-darken tracking-tighter md:font-semibold md:text-lg"
            css={css`
              font-size: 28px;
              ${media("md")} {
                font-size: 18px;
              }
            `}
          >
            {feedback}
          </p>
          <p className="mb-9 text-base font-normal text-blue-darken md:font-normal md:text-sm">
            {hint}
          </p>
          <div className="flex flex-row justify-between md:flex-col">
            <div className="flex flex-row justify-between md:justify-start md:mb-8">
              {photo ? (
                <img
                  className="mr-3"
                  src={photo}
                  alt={name}
                  width="60"
                  height="60"
                />
              ) : null}
              <div className="flex flex-col justify-center">
                <p className="font-Inter text-sm font-normal text-blue-accent">
                  {name}
                </p>
                <p className="font-Inter text-sm font-normal text-blue-darken">
                  {title}
                </p>
              </div>
            </div>
            {logo ? (
              <div className="flex flex-col justify-center h-full my-auto md:flex-row md:justify-start">
                <img
                  className="w-auto h-8 md:h-6"
                  src={logo}
                  alt={company}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </CardSize>
  );
};

export default CarouselCard;
